@import './colors';
@import './sizes';

@mixin inputBorder {
  border: 1px solid $alto;
  border-radius: $radiusSmall;
  outline: 0;
  transition: border-color 300ms;
  &:focus {
    outline: 1px solid $black;
  }
}

@mixin inputBorderError {
  border-color: $error;
  &:focus {
    outline: 1px solid $error;
  }
}

@mixin boxShadow {
  box-shadow: 0px 0px 21px rgba($black, 0.14);
}

@mixin softShadow {
  box-shadow: 0px 1px 8px rgba($black, 0.1);
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@mixin spin {
  animation: spin 2s linear infinite;
}

@keyframes pulse {
  from {
    opacity: 100%;
  }
  to {
    opacity: 20%;
  }
}
@mixin pulse {
  animation: pulse 1.5s infinite alternate;
}

$settleInterpolator: cubic-bezier(0.85, 1.35, 0.57, 1);

@mixin linkStyle {
  transition: all 200ms;
  color: $mariner;
  text-decoration: underline solid transparent;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
  &:hover,
  &:focus-visible {
    color: $mariner;
    text-decoration: underline solid rgba($mariner, 0.5);
  }
  &:focus-visible {
    outline: 2px solid $mariner;
  }
}
