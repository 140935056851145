@import '../sizes';
@import '../colors';
@import '../effects';

.disclaimerGovernment {
  max-height: 95%;
  overflow-y: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1071;
  padding: $paddingXLarge $paddingXLarge $paddingLarge;
  border: 1px solid $alto;
  border-radius: $radiusNormal;
  background-color: $white;
  @include boxShadow();
  width: 60%;
  & .button {
    width: fit-content;
    display: block;
    margin: $marginLarge auto;
  }
  &__overlay {
    width: 100vw;
    height: 100vh;
    z-index: 1070;
    position: fixed;
    background-color: $dim;
  }
}
