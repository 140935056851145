@import '../../effects';

.preparing_portal {
  &__main_content {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    @include pulse;
  }
}
