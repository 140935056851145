@import '../../../colors';
@import '../../../sizes';

.tutorial-page {
  max-height: 100%;
  height: 100%;
  margin-top: $marginNormal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  &__imgs-container {
    height: 50%;
    width: 100%;
    &__imgs {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      &__img {
        box-shadow: 2px 4px 5px 1px $gray-med-2;
        &:not(:last-child) {
          margin-right: $marginNormal;
        }
        img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-height: 100%;
          max-width: 100%;
          display: block;
        }
      }
    }
  }
  &__text {
    height: 50%;
    margin-top: $marginNormal;
    & p {
      margin-bottom: 0.25em;
    }
  }
}
