@import '../node_modules/bootstrap/scss/bootstrap';
@import './bootstrap.scss';
@import './fonts';
@import './accessibility.scss';

body {
  margin: 0;
  font-family: $fontSansSerif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 300;
  font-size: 45px
}

h2 {
  font-weight: 700;
}
