@import '../sizes';
@import '../colors';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

$standardTransition: all 300ms ease-in-out;

$menuItemHeight: 56.8px;
$iconHeight: $menuItemHeight - $marginSmall - $marginSmall;
$menuWidth: 38ch;

$borderTop: $borderNormal;

.main_menu {
  position: relative;
  max-width: $menuBarWidth;
  width: $menuBarWidth;
  padding-right: 0;
  padding-left: 0;
  z-index: 10;
  &__inline.main_menu {
    max-width: calc(#{$menuBarWidth} + #{$menuWidth});
    width: calc(#{$menuBarWidth} + #{$menuWidth});
  }
  &__content_container {
    position: absolute;
    top: 0;
    height: 100%;
    width: $menuWidth;
    transition: $standardTransition;
    background-color: $white;
    z-index: -1;
    &__expanded {
      left: $menuBarWidth;
    }
    &__collapsed {
      left: calc(#{$menuBarWidth} - #{$menuWidth});
    }
    &__content {
      width: $menuWidth;
      background-color: $white;
      position: sticky;
      top: 0;
      overflow: auto;
      box-shadow: 2px 4px 8px 2px $gray-med-2;
      & > .navbar {
        padding: 0;
      }
      @media screen and (-ms-high-contrast: active) {
        border: 1px solid white !important;
      }
    }
  }
  &__bar {
    position: relative;
    height: 100%;
    transition: $standardTransition;
    z-index: 5;
    background-color: $headerColor;
    &__buttons {
      background-color: $headerColor;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: $menuBarWidth;
      box-shadow: 3px 3px 10px 1px $gray;
      &__top,
      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__top {
        position: sticky;
        top: 0;
        border-top: $borderTop solid $mustard;
        width: 100%;
      }
      &__bottom {
        margin-top: auto;
        position: sticky;
        bottom: 0;
        & .button {
          margin-bottom: $marginLarge;
        }
      }
      &.button {
        position: relative;
      }
      &__btn {
        padding: $paddingSmall 0;
        color: $white;
        width: $menuBarWidth;
        height: $menuItemHeight;
        @media screen and (-ms-high-contrast: active) {
          border: 1px solid white !important;
        }
        &__expanded {
          & .bars_icon {
            height: 0;
            width: 0;
            pointer-events: none;
          }
          & .x_icon {
            height: $iconHeight;
          }
        }
        &__collapsed {
          & .bars_icon {
            height: $iconHeight;
          }
          & .x_icon {
            height: 0;
            width: 0;
            pointer-events: none;
          }
        }
        &__icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: $iconHeight;
          width: $menuBarWidth;
          transition: $standardTransition;
          &:hover,
          &:focus-visible {
            color: $mustard;
            filter: drop-shadow(0px 0px 2px #{$mustard});
          }
        }
        &__files {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-left: $marginSmall;
          margin-top: $marginSmall;
          color: $headerColor;
          font-weight: bold;
          font-size: 9pt;
          pointer-events: none;
        }
        & svg {
          height: $iconHeight;
        }
      }
    }
  }

  &__accordion_arrow_open,
  &__accordion_arrow_closed {
    margin-left: $marginNormal;
    vertical-align: middle;
    transition: $standardTransition;
    text-decoration: underline solid white;
  }
  &__accordion_arrow_open {
    transform: rotate(-90deg);
  }
  &__accordion_button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(2em + 4px);
    padding-top: 4px;
    &:focus-visible {
      outline: 2px solid #235faf;
      outline-offset: 7px;
      border-radius: 6px;
    }
    &:hover,
    &:focus-visible {
      text-decoration: underline solid #235faf;
    }
  }

  .card-header {
    background-color: $white;
    border: none;
    padding: 10px 14px;
  }
  .card {
    border: none;
  }
  .navbar-nav {
    border-top: $borderTop solid $mustard;
    width: 100%;
    .accordion > :not(:last-child) {
      border-bottom: 1px solid $botticelli;
    }
    .nav-link {
      padding: $paddingLarge;
      padding-right: 0;
      margin: 0 $marginSmall;
      &:focus-visible {
        outline-offset: -4px;
        border-radius: 16px;
      }
      &.active {
        border-radius: unset;
        background-color: lighten($headerColor, 20);
        font-weight: bold;
        margin-left: 0;
        margin-right: 0;
        &:focus-visible {
          box-shadow: inset 0 0 1px 6px #134b96, inset 0px 0 1px 8px #ffd24d;
        }
        &:hover,
        &:focus-visible {
          text-decoration: underline solid $mustard;
        }
        &.button--link {
          color: $white;
        }
      }
    }

    .card-body {
      padding: 0;
      & .button {
        margin-left: 1em;
      }
    }
  }
}

// .main_menu {

//   &.main_menu__collapsed {
//     transition: $standardTransition;
//   }
//   &__collapsed_icon {
//     display: inline-block;
//     transform: scaleX(-100%);
//   }
// }

@include media-breakpoint-down(md) {
  .main_menu {
    width: 100%;
    &__content {
      width: 100%;
      .navbar {
        width: 100%;
      }
    }
    &__content_container__expanded {
      width: 100%;
    }
    &__content_container__collapsed {
      height: 3em;
    }
  }
}
