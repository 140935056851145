@import '../colors';
@import '../sizes';

.simple_notification {
  @media screen and (-ms-high-contrast: active) {
    border: 1px solid white !important;
    top: 50%;
    transform: translateY(-50%);
  }  
  margin-left: $marginLarge;
  padding: 0.3em 0.35em;
  border-radius: 2em;
  font-weight: bold;
  min-width: 1.7em;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: $white;
  &__container {
    box-shadow: 0px 0px 8px 1px darken($error, 5%);
    background-color: darken($error, 5%);
    margin-top: -8px;
  }
  &__hidden {
    opacity: 0;
  }
  &__value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
