@import '../colors';
@import '../sizes';

.section_header {
  @media screen and (-ms-high-contrast: active) {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-bottom: 8px;
  }
  background: $gray-light-2;
  background-image: url('../../public/bg-pattern-gray.png');
  box-shadow: inset 0 -23px 52px -21px rgba(0, 0, 0, 0.4);
  padding: $paddingLarge;
  &__title {
    h1 {
      margin: $marginLarge 0;
    }
  }
  &__breadcrumbs {
    &__link {
      color: $kashmirBlue;
      font-size: $fontSmall;
      text-decoration: none;
      font-weight: 400;
      &:hover {
        color: $kashmirBlue;
      }
    }
    &__separator {
      color: $breadcrumbSeparator;
    }
  }
}
