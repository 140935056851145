@import '../colors';
@import '../effects';
@import '../sizes';

.cardRoot {
  margin-right: 10px;
  border-width: thick;
  text-align: center;
  word-wrap: normal;
  height: 200px;
  cursor: pointer;
}
.cardRoot:hover {
  box-shadow: inset 0 0 0 3px #235faf;
}
.icon {
  padding-top: 1em;
}
