@import '../colors';
@import '../effects';
@import '../sizes';

.dirs_card {
  @media screen and (-ms-high-contrast: active) {
    border: 1px solid white !important;
  }
  @include boxShadow();
  display: flex;
  border-top: 7px solid $yellowish;
  &--action {
    border-top-width: 4px;
    transition: border-color 200ms;
    @include linkStyle;
    &:hover,
    &:focus-visible {
      border-top-color: $mariner;
      .dirs_card__action_body {
        &__icon {
          color: $mariner;
        }
      }
    }
  }
  &--subtle {
    border-top: 0;
    margin: 0;
    @include softShadow();
  }
  &__action_body {
    min-height: 180px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 $paddingLarge;
    &__icon,
    &__label {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      height: 50%;
      padding: $paddingNormal;
    }
    &__icon {
      align-items: center;
      border-bottom: 1px solid $gray;
      color: $gray;
      transition: color 200ms;
    }
    &__label {
      font-size: $fontMedium;
      color: $mariner;
      text-align: center;
      line-height: 120%;
    }
  }
}
