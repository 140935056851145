@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';
@import '../sizes';
@import '../colors';

.footer {
  &__container {
    margin: $marginLarge auto 0;
    padding: $paddingLarge 0;
    border-top: $borderSmall double $alto;
    font-size: $fontSmall;
    overflow: hidden;
    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      border-left: none;
      border-right: none;
    }
    .list-group-item:first-child,
    .list-group-item:last-child {
      border: none;
    }
    .list-group-item:nth-child(2) {
      border-top-width: 1px;
    }
    &__contact-btn {
      width: 100%;
    }
    &__contact-link {
      display: none;
    }

    &__fcc-seal {
      clip-path: polygon(0 0, 184px 0, 184px 184px, 0 184px);
      width: 184px;
      height: 184px;
      object-fit: cover;
      object-position: top;
    }

    @include media-breakpoint-up(md) {
      .list-group-item {
        border: none;
      }
      &__contact-btn {
        display: none;
      }
      &__contact-link {
        display: block;
      }
    }
  }
}
