.file-upload-processing {
  z-index: 1071;
  &__backdrop {
    z-index: 1070;
  }
  &__pulse {
    animation: pulse 1.5s infinite alternate;
    font-weight: bold;
    font-size: 2em;
  }
  &__large {
    font-size: 1.3em;
  }
}
