@import '../colors';
@import '../sizes';

.alert {
  margin: 0;
  padding: 0;

  &__container {
    width: 100%;
    display: inline-flex;
    margin: 0;
    padding: $paddingSmall $paddingLarge;
    color: $gray-dark;
    border: 1px solid transparent;
    border-radius: $borderSmall;
    &.align_left {
      justify-content: flex-start;
    }
    &.align_right {
      justify-content: flex-end;
    }
    &.align_center {
      justify-content: center;
    }
  }

  &__children {
    margin: 0 $marginNormal 0 0;
    & * {
      color: $gray-dark;
    }
    & a:hover {
      color: $gray-med;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 2ch;
  }

  &__close_btn {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    color: $black;
    opacity: 0.2;
    font-weight: bold;
    font-size: 1.5em;
    position: absolute;
    right: $marginNormal;
    top: -4px;
  }
  &__close_btn:hover {
    opacity: 0.5;
  }

  @mixin typeColors($background, $border) {
    background-color: $background;
    border-color: $border;
  }

  &__info {
    @include typeColors($alertInfoBackground, $alertInfoBorder);
  }
  &__success {
    @include typeColors($alertSuccessBackground, $alertSuccessBorder);
  }
  &__warning {
    @include typeColors($alertWarningBackground, $alertWarningBorder);
  }
  &__danger {
    @include typeColors($alertDangerBackground, $alertDangerBorder);
  }
}
