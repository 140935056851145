@import '../sizes';

.session-expiring {
  z-index: 2010;
  &__backdrop {
    z-index: 2000;
  }
  &__countdown {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
  }
  &__msg {
    text-align: center;
    font-size: 1.3em;
  }
  &__buttons {
    margin: 0 auto;
    &__show {
      display: block;
    }
    &__hide {
      display: none;
    }
    &__button {
      margin-right: $marginLarge;
      font-size: 1.2em;
    }
  }
}
