@import '../../colors';
@import '../../sizes';

.tutorial-dialog {
  &__container {
    height: 60vh;
    & .row:first-child {
      height: calc(100% - 2em);
    }
    & .row:nth-child(2) {
      height: 2em;
    }
    &__chevron {
      display: inline-block;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 10em;
      filter: drop-shadow(8px 3px 4px $gray-dark);
      transition: all 0.1s;
      & svg {
        width: 100%;
      }
      &:hover:not(.disabled) {
        top: calc(50% + 2px);
        left: calc(50% + 7px);
        filter: drop-shadow(2px 1px 1px $black) drop-shadow(0px 1px 4px $white)
          drop-shadow(0px -1px 4px $white) drop-shadow(1px 0px 4px $white)
          drop-shadow(-1px 0px 4px $white)
          drop-shadow(0px 1px 0px darken($mustard, 16))
          drop-shadow(0px -1px 0px darken($mustard, 16));
      }
      &:not(.disabled) {
        color: $mariner;
      }
      &.disabled {
        color: $gray-med;
      }
    }
    &__main-content {
      border: 5px solid $mustard;
      border-radius: 5px;
      max-height: 100%;
      overflow-y: scroll;
    }
    &__dots {
      display: flex;
      justify-content: center;
      &__dot {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: $mariner;
        margin: $marginNormal;
        border: 4px solid $white;
        border-radius: 10px;
        &.selected {
          position: relative;
          border-radius: 0;
          transform: rotate(45deg);
          background-color: $mustard;
          width: 22px;
          height: 22px;
          cursor: default;
          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border: 2px solid $mariner;
          }
          &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            transform: rotate(-45deg);
            left: 5px;
            bottom: -5px;
            border-bottom: 3px solid $mariner;
          }
        }
        &:hover:not(.selected),
        &:focus-visible:not(.selected) {
          outline: 5px solid $mustard;
        }
      }
    }
    &__footer {
      flex-direction: column;
    }
  }
  &__download-btn {
    margin: auto;
  }
}
