@import '../colors';
@import '../sizes';

.user_status {
  @media screen and (-ms-high-contrast: active) {
    border: 1px solid white;
  }
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: $gray-light-3;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-size: cover;
  &__initials {
    font-size: $fontLarge;
  }
  &__toggle {
    background-color: transparent;
    border: none;
    border-radius: 24px;
    padding: 0;
  }
}

#current_user_email,
.current_user_email {
  display: none !important;
}

.switchCompany {
  padding: 0px;
  border-radius: 0px;
}

.dropdownMenu {
  padding-bottom: 0px;
}

.dropdownDivider {
  margin: 0px;
}

.button_switch {
  border-radius: 0px;
  background-color: #235faf;
  --bs-btn-hover-bg: #163c6f;
  --bs-btn-hover-color: white;
  --bs-btn-active-bg: #163c6f;
}

.button_logout {
  background-color: white;
  border-radius: 0px;

  --bs-btn-border-color: #235faf;
  color: #212529;
  --bs-btn-hover-bg: white;
}

.button_logout:hover {
  color: #212529;
  box-shadow: inset 0 0 0 3px #235faf;
}

.button_logout:active {
  --bs-btn-active-bg: white;
  --bs-btn-active-color: #212529;
}

.avatarRow {
  flex-wrap: nowrap;
}
