// Sizes should be defined here to allow cohesive sizing across the site.

$marginSmall: 4px;
$marginNormal: 8px;
$marginLarge: 16px;
$marginXLarge: 24px;
$marginXXLarge: 48px;

$paddingSmall: 4px;
$paddingNormal: 8px;
$paddingLarge: 16px;
$paddingXLarge: 24px;

$borderSmall: 4px;
$borderNormal: 8px;
$borderLarge: 16px;

$radiusSmall: 4px;
$radiusNormal: 6px;
$radiusLarge: 8px;

$fontSmall: 11pt;
$fontMedium: 14pt;
$fontLarge: 16pt;
$fontXLarge: 20pt;
$fontXXLarge: 26pt;

$menuBarWidth: 70px;
