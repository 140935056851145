// This allows us to override global styles from Bootstrap and apply our own
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/react-bootstrap-typeahead/css/Typeahead.scss';
@import '../node_modules/react-bootstrap-typeahead/css/Typeahead.bs5.scss';

@import 'colors';
@import 'sizes';
@import 'fonts';
@import 'effects';

// FIX: Clear buttons on React Bootstrap Typeahead appear too close to the
//      right for some reason; this addresses this until a more reasonable
//      fix is found.
.rbt-aux {
  right: 0.5rem;
}

body {
  font-family: $fontSansSerif;
}

.container__body {
  padding: 0;
  margin-top: 0;
  margin-bottom: $marginXXLarge;
  & > * {
    max-width: 100%;
    width: 100%;
  }
}

.dropdown-menu[data-bs-popper] {
  margin-top: 10px;
}

.dropdown-menu-end[data-bs-popper]::before {
  content: ' ';
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 10px;
  height: 10px;
  border-right: none;
  border-bottom: none;
  transform: rotate(45deg);
  top: -6px;
  right: 18px;
  background-color: white;
}

.rbt .rbt-token-remove-button {
  background: none;
  border: none;
}

.rbt-token-removeable:not(.rbt-token-active) {
  background-color: $alertInfoBackground;
  color: $headerColor;
}

.modal {
  &-header {
    background-color: $mariner;
    color: $white;
    font-weight: bold;
    text-align: center;
  }
  &-title {
    width: 100%;
  }
}

.navbar-light .navbar-nav .nav-link {
  @include linkStyle;
}

.navbar-dark .navbar-nav .nav-link {
  @include linkStyle;
}

a:not(.dropdown-item) {
  @include linkStyle;
}

.under_dev {
  font-size: 3em;
  text-align: center;
  margin: 0 auto;
  & svg path {
    color: darken($warning, 25%);
    stroke: $black;
    stroke-width: 5px;
    animation: hammerHit 2s ease-in infinite;
  }
  &.hurricane {
    background-image: url('https://www.nasa.gov/sites/default/files/styles/full_width_feature/public/thumbnails/image/21457942_1775841795789423_6361884645929817029_o.jpg');
    -webkit-text-stroke: 1px red;
    background-position: 50%;
  }
}

@keyframes hammerHit {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(45deg) translate(50%, -50%);
  }
  100% {
    transform: rotate(0);
  }
}

.accordion-button:not(.collapsed) {
  background-color: $gray-light-2;
  color: $black;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.form-control:disabled,
input:disabled {
  background-color: $gray-light;
  color: $gray-dark;
}
.form-control::file-selector-button {
  background-color: $gray-light-2;
}

/////////////////////
/// Print styling ///
/////////////////////
@media print {
  .print_hidden,
  .print_hidden * {
    display: none !important;
  }
  .container__body {
    max-width: 100%;
  }
}

.high-contrast-border {
  @media screen and (-ms-high-contrast: active) {
    border: 1px solid white !important;
    padding: 16px 0;
  }
}
