@import '../colors';
@import '../sizes';

$borderColor: $gray;

.session-notifications {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 100vh;
  z-index: 1065;
  pointer-events: none;
  &__monitor-btn {
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
  & .alert__children {
    white-space: pre-line;
  }
  &__notification-list {
    position: relative;
    border: 4px solid $borderColor;
    outline: 2px solid lighten($borderColor, 20);
    border-top: none;
    border-radius: 0 0 $radiusLarge $radiusLarge;
    background-color: $mariner;
    overflow-y: auto;
    transition: max-height 500ms ease-in-out;
    pointer-events: all;
    &__open {
      max-height: 80vh;
    }
    &__closed {
      max-height: 0;
    }
    &__pulse {
      box-shadow: 0 0 0 0 rgba(204, 0, 0, 1);
      animation: noti_pulse 2s infinite;
    }
    &__clear-all {
      display: block;
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  &__toggle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -4px);
    text-align: center;
    width: 20%;
    background: linear-gradient(0deg, lighten($borderColor, 20), $borderColor);
    height: 16px;
    border-radius: 0 0 100% 100%;
    border-bottom: 2px solid lighten($borderColor, 30);
    pointer-events: all;
    &:hover {
      outline: 3px solid $mustard;
    }
    & .button {
      display: inline-block;
      width: 95%;
      height: 100%;
      top: 0px;
    }
    &__hr {
      margin-top: 0;
      margin-bottom: 2px;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      background: lighten($borderColor, 30);
      border-radius: 20%;
      &:first-child {
        position: relative;
        top: 20%;
        width: 80%;
      }
      &:last-child {
        position: relative;
        top: 25%;
        width: 60%;
      }
    }
  }
}

@keyframes noti_pulse {
  0% {
    box-shadow: 0 0 5px 0 rgba(204, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 5px 30px rgba(204, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 5px 0 rgba(204, 0, 0, 0);
  }
}
