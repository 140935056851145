@import '../sizes';
@import '../colors';

$barHeight: 15pt;

.custom-loading-bar {
  border: 1px solid $gray-med-2;
  border-radius: $radiusNormal;
  margin-bottom: $marginSmall;
  position: relative;
  &__close-btn {
    position: absolute;
    right: $marginNormal;
    color: $gray-med;
    &:hover,
    &:focus-visible {
      font-weight: bold;
      color: $black;
    }
  }
  &__title {
    margin-left: $marginSmall;
    font-size: 1.1em;
    font-weight: bold;
  }
  &__bar {
    position: relative;
    border: 1px solid $gray-med;
    border-radius: $radiusSmall;
    margin: 0 $marginSmall;
    width: calc(100% - #{$marginSmall} - #{$marginSmall});
    height: $barHeight;
    margin-bottom: calc(#{$barHeight} + #{$marginSmall});
    &__progress {
      position: relative;
      height: $barHeight;
      &__default {
        background-color: $mariner;
      }
      &__good {
        background-color: $valid;
      }
      &__bad {
        background-color: $error;
      }
    }
    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 14pt;
      color: $white;
    }
  }
  &__value {
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
  }
}
