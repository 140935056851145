@import '../sizes';
@import '../colors';

.social-media {
  &__twitter,
  &__facebook,
  &__youtube,
  &__flickr,
  &__instagram {
    display: inline-block;
    height: 51px;
    width: 51px;
    margin: $marginLarge $marginSmall;
    img {
      position: absolute;
    }
    &:focus-visible {
      opacity: 0.5;
    }
  }

  &__twitter img {
    top: calc(#{24px} - 204px);
    clip-path: polygon(0 204px, 51px 204px, 51px 255px, 0 255px);
  }
  &__facebook img {
    top: calc(#{24px} - 488px);
    clip-path: polygon(0 488px, 51px 488px, 51px 539px, 0 539px);
  }
  &__youtube img {
    top: calc(#{24px} - 417px);
    clip-path: polygon(0 417px, 51px 417px, 51px 468px, 0 468px);
  }
  &__flickr img {
    top: calc(#{24px} - 346px);
    clip-path: polygon(0 346px, 51px 346px, 51px 397px, 0 397px);
  }
  &__instagram img {
    top: calc(#{24px} - 275px);
    clip-path: polygon(0 275px, 51px 275px, 51px 326px, 0 326px);
    background-position: 0 -275px;
  }
}
