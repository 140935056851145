@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import './colors';
@import './sizes';

.app {
  &__content {
    max-width: 100%;
    position: relative;
    margin: 0;
    flex-wrap: nowrap;
    &__wrapper {
      max-width: calc(100% - #{$menuBarWidth});
      width: calc(100% - #{$menuBarWidth});
      margin-left: $marginNormal;
      & main {
        padding-top: 80px;
        max-width: 100%;
        width: 100%;
        margin: 0;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-light;
  box-shadow: inset 0px 0 4px $kashmirBlue;
  border-left: 1px solid $alto;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gray-med-2;
  border-radius: 14px;
  box-shadow: inset -3px 0 6px $kashmirBlue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $kashmirBlue;
  box-shadow: inset 2px 0 6px $gray-med-2;
}

.modal {
  & .btn-close:not(.rbt-close) {
    filter: invert(1);
    font-size: $fontLarge;
  }
}

@include media-breakpoint-down(md) {
  .app__content_wrapper {
    flex-direction: column;
  }
}
