// Fonts should be unified here
$fontSerif: serif;
$fontSansSerif: "Open Sans", "Helvetica", "Arial", sans-serif;
$fontMonospace: monospace;

@font-face {
    font-family: "Open Sans";
    src: url('../public/fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    src: url('../public/fonts/OpenSans-Bold.ttf');
}

@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    src: url('../public/fonts/OpenSans-Light.ttf');
}
