@import '../colors';
@import '../sizes';

.header {
  background-color: $headerColor;

  &__skip-content.button {
    font-weight: bold;
    overflow: hidden;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    &:focus,
    &:focus-visible {
      overflow: unset;
      white-space: nowrap;
      width: unset;
      height: unset;
      margin-right: $marginNormal;
      padding: 0 $paddingNormal;
    }
  }

  &__branding {
    height: 38px;
    width: 175px;
  }

  .navbar-collapse .navbar-nav {
    .nav-link {
      color: $white;
      font-size: $fontMedium;
      &:hover,
      &.active:hover {
        background-color: $white;
        color: $black;
      }
      &.active {
        color: $white;
        border-bottom: 6px solid orange;
      }
    }
  }
}

/////////////////////
/// Print styling ///
/////////////////////

@media print {
  .header {
    img {
      filter: invert(30%);
    }
  }
}

.loggedIn {
  color: #ffffff;
  text-align: right;
}

.companyName {
  color: #ffcd42;
}
