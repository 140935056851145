@import '../sizes';
@import '../colors';

.error_boundary {
    margin: $marginNormal;
    width: 100%;
    padding: $paddingNormal;
    background-color: $botticelli;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radiusNormal;
}