@import '../effects';
@import '../colors';
@import '../sizes';

@mixin setBgAndHover($bgColor, $hoverAmount: 15, $hoverColor: $bgColor) {
  background-color: $bgColor;
  transition: all 300ms;
  &:hover,
  &:focus {
    background-color: adjust-color($hoverColor, $lightness: -$hoverAmount);
  }
}

.button {
  cursor: pointer;
  border-radius: $radiusLarge;
  position: relative;
  &--large {
    padding: $paddingNormal $paddingLarge;
    font-size: $fontLarge;
    font-weight: bold;
    border-radius: 6px;
  }
  &--normal {
    padding: $paddingSmall $paddingLarge;
    border-radius: 4px;
  }
  &--small {
    font-size: $fontSmall;
    padding: ($paddingSmall * 0.8) $paddingNormal;
    border-radius: 3px;
  }
  &--x-small {
    font-size: ($fontSmall * 0.75);
    padding: ($paddingSmall * 0.5) ($paddingNormal * 0.5);
    border-radius: 3px;
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
  &--default {
    @include setBgAndHover($white, -45, $mariner);
    border-color: $gray-light-3;
  }
  &--default,
  &--primary,
  &--success,
  &--warning,
  &--danger {
    background: $white;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    &:focus-visible {
      outline: 2px solid $mariner;
      z-index: 1;
    }
  }
  &--primary {
    @include setBgAndHover($mariner);
    color: $white;
    border-color: darken($mariner, 20);
    &:focus-visible {
      outline: 4px solid $yellowish;
      z-index: 1;
    }
  }
  &--secondary {
    @include setBgAndHover($boulder);
    color: $white;
    border-color: darken($boulder, 20);
  }
  &--success {
    @include setBgAndHover($valid);
    color: $white;
    border-color: darken($valid, 10);
  }
  &--warning {
    @include setBgAndHover($warning, 25);
    border-color: darken($warning, 30);
  }
  &--danger {
    @include setBgAndHover($error);
    color: $white;
    border-color: darken($error, 10);
  }
  &--custom {
    padding: 0;
    margin: 0;
  }
  &--link {
    padding: 0;
    border: 0;
    outline: 0;
    background: none;
    @include linkStyle;
    &:focus-visible {
      outline: 1px solid $mariner;
      z-index: 1;
    }
  }
}
