// Colors should be defined here, not in individual .scss files, and ideally in HSL.
$gray: hsl(0, 0%, 50%);
$gray-light: hsl(0, 0%, 98%);
$gray-light-2: hsl(0, 0%, 93%);
$gray-light-3: hsl(0, 0%, 87%);
$gray-med: hsl(0, 0%, 40%);
$gray-med-2: hsl(0, 0%, 69%);
$gray-dark: hsl(0, 0%, 20%);
$gray-dark-2: hsl(0, 0%, 7%);
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);
$error: hsl(0, 100%, 40%);
$error-dark: hsl(0, 100%, 35%);
$valid: hsl(117, 100%, 27%);
$valid-dark: hsl(117, 100%, 22%);
$warning: hsl(57, 100%, 70%);
$information: hsl(236, 100%, 35%);
$information-dark: hsl(236, 100%, 30%);
$mariner: hsl(214, 67%, 41%);
$mariner-light: hsl(214, 67%, 81%);
$cloudBurst: hsl(215, 36%, 18%);
$toryBlue: hsl(214, 78%, 33%);
$kashmirBlue: hsl(216, 29%, 47%);
$mustard: hsl(45, 100%, 65%);
$yellowish: hsl(44, 100%, 63%);
$boulder: hsl(0, 0%, 45%);
$alto: hsl(0, 0%, 82%);
$botticelli: hsl(214, 29%, 85%);
$toreaBay: hsl(217, 76%, 31%);
$pickledBluewood: hsl(200, 33%, 26%);
$headerColor: hsl(217, 76%, 31%);
$dim: hsla(0, 0%, 0%, 0.5);

$breadcrumbSeparator: hsl(0, 0%, 80%);

$alertInfoBackground: hsl(197, 64%, 95%);
$alertInfoBorder: hsl(198, 22%, 85%);
$alertSuccessBackground: hsl(75, 43%, 91%);
$alertSuccessBorder: hsl(73, 19%, 82%);
$alertWarningBackground: hsl(49, 89%, 93%);
$alertWarningBorder: hsl(50, 34%, 83%);
$alertDangerBackground: hsl(354, 45%, 91%);
$alertDangerBorder: hsl(353, 19%, 82%);

$mapMarkerRed: hsl(0, 100%, 53%);
$mapMarkerGreen: hsl(120, 100%, 53%);
$mapMarkerBlue: hsl(240, 100%, 53%);
$mapMarkerPurple: hsl(301, 80%, 50%);
